<template>
  <div class="lg:flex lg:items-center lg:justify-between px-4 pb-4 sm:px-0">
    <div class="min-w-0">
      <h2
        class="text-2xl font-semibold leading-5 text-gray-900 sm:text-xl sm:truncate"
      >
        <slot></slot>
      </h2>
      <div
        class="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6"
      >
        <slot name="meta"></slot>
      </div>
    </div>
    <div class="mt-5 flex lg:mt-0 lg:ml-4">
      <slot name="actions"></slot>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
};
</script>
