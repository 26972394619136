<template>
  <article class="shadow sm:rounded-md sm:overflow-hidden bg-white w-full">
    <slot></slot>
  </article>
</template>

<script>
export default {};
</script>

<style></style>
