<template>
  <h4 class="text-base font-medium text-black">
    <slot></slot>
  </h4>
</template>

<script>
export default {};
</script>

<style></style>
