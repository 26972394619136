import { STATS } from "../endpoints";
import api from "../api";

export default {
    index(query = null) {
        let url = new URL(STATS);

        if (query)
            url.search = new URLSearchParams(query).toString();

        return api.get(url);
    },
}