<template>
  <div class="px-4 py-5 bg-white sm:p-6 text-sm">
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
