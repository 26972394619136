<template>
  <app-layout>
    <template v-slot:header> Dashboard </template>

    <page-section>
      <page-section-body class="flex space-x-4">
        <v-card class="w-1/3">
          <v-card-body>
            <v-card-body-title> Vanzatori </v-card-body-title>
            <p class="text-lg font-bold">{{ stats.vendors }}</p>
          </v-card-body>
        </v-card>
        <v-card class="w-1/3">
          <v-card-body>
            <v-card-body-title> Comenzi </v-card-body-title>
            <p class="text-lg font-bold">{{ stats.orders }}</p>
            <p class="text-sm" >Furnizori {{ stats.vendor_orders }}</p>
          </v-card-body>
        </v-card>
        <v-card class="w-1/3">
          <v-card-body>
            <v-card-body-title> Cereri </v-card-body-title>
            <p class="text-lg font-bold">{{ stats.rfqs }}</p>
          </v-card-body>
        </v-card>
        <v-card class="w-1/3">
          <v-card-body>
            <v-card-body-title> Castig comisioane </v-card-body-title>
            <p class="text-lg font-bold">
              {{ formatPrice(stats.vendor_charges) }}
            </p>
          </v-card-body>
        </v-card>
      </page-section-body>
    </page-section>
    <page-section>
      <page-section-header> Actiuni rapide </page-section-header>
      <page-section-body>
        <button class="btn" @click="archiveRfqs">Arhiveaza cererile</button>
      </page-section-body>
    </page-section>
  </app-layout>
</template>

<script>
import { priceFormatter } from "@/helpers";
import VCard from "../components/ui/VCard.vue";
import VCardBody from "../components/ui/VCardBody.vue";
import VCardBodyTitle from "../components/ui/VCardBodyTitle.vue";
import AppLayout from "../layout/AppLayout.vue";
import archivedRfqsApi from "../api/resources/archivedRfqsApi";
import statsApi from "../api/resources/statsApi";
import PageSectionHeader from "../components/ui/PageSectionHeader.vue";
import PageSection from "../components/ui/PageSection.vue";
import PageSectionBody from "../components/ui/PageSectionBody.vue";

export default {
  components: {
    VCard,
    AppLayout,
    VCardBody,
    VCardBodyTitle,
    PageSectionHeader,
    PageSection,
    PageSectionBody,
  },
  name: "Dashboard",

  data() {
    return {
      loading: false,
      stats: {},
    };
  },

  methods: {
    formatPrice(price) {
      return priceFormatter(price);
    },

    statsIndex() {
      this.loading = true;
      statsApi
        .index()
        .then((response) => {
          this.stats = response.data;
          this.loading = false;
        })
        .catch((error) => console.log(error));
    },

    archiveRfqs() {
      this.loading = true;
      archivedRfqsApi
        .store()
        .then(() => {
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
  },

  mounted() {
    this.statsIndex();
  },
};
</script>

<style></style>
