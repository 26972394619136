<template>
  <div class="px-4 sm:p-0">
    <slot></slot>
  </div>
</template>

<script>
export default {
  components: {},
};
</script>
